<template>
<CModalExtended
  :title="title"
  color="dark"
  size="lg"
  :show.sync="modalActive"
  :close-on-backdrop="false"
>
  <CRow>
    <CCol sm="12">
      <CCard>
        <CCardBody>
          <CRow class="d-flex justify-content-end col-sm-12">
            <CCol sm="11" >
              <CInput
                v-model.trim="$v.abbrev.Abbreviation.$model"
                v-uppercase
                :horizontal="{ label: 'col-sm-3', input: 'col-sm-8'}"
                :invalid-feedback="errorMessage($v.abbrev.Abbreviation)" 
                :is-valid="hasError($v.abbrev.Abbreviation)"
                :label="$t('label.abbreviation')"
                addLabelClasses="required text-right"
                maxlength="5"
                :placeholder="$t('label.newShippingLineAbbreviation')"
              >
              </CInput>
            </CCol>
            <CCol sm="11">
              <CCollapse :show="editMode">
                <CSelect
                :value.sync="abbrev.Status"
                :is-valid="statusSelectColor"
                :horizontal="{ label: 'col-sm-3', input: 'col-sm-8'}"
                :label="$t('label.status')"
                addLabelClasses="text-right"
                :options="statusOptions"
                />
              </CCollapse>
            </CCol>
            <CCol sm="12" class="d-flex justify-content-end align-items-center">
              <CCollapse :show="editMode">
              </CCollapse>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol sm="12">
      <dataTableExtended
     class="align-center-row-datatable"
        hover
        sorter
        smail
        column-filter
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :noItemsView="tableText.noItemsViewText"
        :items="formatedItems"
        :fields="fields"
        :loading="isLoading"
        :items-per-page="5"
        :active-page="activePage"
        pagination
         
      >
        <template #RowNumber="{ item }">
          <td class="center-cell">
            {{ item.RowNumber }}
          </td>
        </template>
        <template #loading>  <loading/> </template>

        <template #Status="{item}">
          <td class="center-cell">
            <CBadge :color="getBadge(item.Status)">
              {{ $t('label.'+item.Status) }}
            </CBadge>
          </td>
        </template>

        <template #Details="{ item }">
          <td class="center-cell">
              <CButtonGroup>
                <CButton
                  color="edit"
                  size="sm"
                  v-c-tooltip="{
                    content: $t('label.edit')+' '+$t('label.abbreviation'),
                    placement: 'top-end'
                  }"
                  @click="toggleEdit(item)"
                >
                  <CIcon name="pencil" />
                </CButton>
            </CButtonGroup>
          </td>
        </template>
      </dataTableExtended>
    </CCol>
  </CRow>

  <template #footer>
    <CButton
        color="add"
        class="d-flex align-items-center mr-2"
        :disabled="isSubmit"
        @click="editMode?statusConfirmation():submit()"
        >
      <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
    </CButton>
    <CButton
      color="wipe"
      class="d-flex align-items-center"
      :disabled="isSubmit"
      @click.stop="toggle(false)"
    >
      <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
    </CButton>

  </template>
</CModalExtended>
</template>

<script>
import { DateFormater, tableTextHelpers } from '@/_helpers/funciones';
import AbbrevValidations from '@/_validations/linea-naviera/AbbrevFormValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import GeneralMixin from '@/_mixins/general';

function fields() {
  return[
  { 
    key: 'RowNumber', 
    label: '#',
    _style: 'width:1%; text-align:center;', filter: false,
  },
  { key: 'Abbreviation', label: this.$t('label.abbreviation'), _classes:'text-uppercase text-center'},
  { key: 'TransaLogin', label: this.$t('label.user'), _classes:'text-uppercase'},
  { key: 'FormatedDate', label: this.$t('label.date'),  _classes:'text-center'},
  { key: 'Status', label: this.$t('label.status'),  _classes:'text-center'},
  { 
    key: 'Details', 
    label: '', 
    _style: 'width:1%', 
    sorter: false, 
    filter: false, 
    _classes:'text-center'
  }
]}

//Data
function data() {
  return {
    modalActive: this.modal,
    isSubmit: false,    
    items: [],
    isLoading: false,
    activePage: 1,
    abbrev: {
      LineAbbrevId: '',
      ShippingLineId: '',
      Abbreviation: '',
      Status: 1
    },
    editMode: false,
    oldStatus: 1
  }
}

//Methods
function getLineaAbreviaturas(idLinea) {
  this.isLoading = true;
  let parametros = {
    ShippingLineId: idLinea,
    filter: 'ALL'
  }
  this.$http.get('ShippingLineAbbrev-list', parametros)
  .then(response => {
    this.items = response.data.data;
  }).catch(err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoading = false;
  });
}
function toggle(newVal) {
  if(newVal){
    this.getLineaAbreviaturas(this.lineaData.ShippingLineId);
    this.abbrev.ShippingLineId = this.lineaData.ShippingLineId;
  }else
    {
      this.abbrev.ShippingLineId = '';
      this.resetForm();
    }

  this.modalActive = newVal;
}
function toggleEdit(abbrv) {
  this.setForm(abbrv);
  this.editMode = true;
}
function submit() {
    try { 
      this.$v.abbrev.$touch();

      if (this.$v.abbrev.$error) {
        throw this.$t('label.errorsPleaseCheck');
      }
      this.isSubmit = true;

      let metodo = this.editMode ? 'PUT':'POST';
      let ruta = this.editMode ? 'ShippingLineAbbrev-update' : 'ShippingLineAbbrev-insert'; 
      let LineAbbrevJson = this.editMode
        ? {
            LineAbbrevId: this.abbrev.LineAbbrevId,
            ShippingLineId: this.abbrev.ShippingLineId,
            Abbreviation: this.abbrev.Abbreviation,
            Status: this.abbrev.Status
          }
        : {
            ShippingLineId: this.abbrev.ShippingLineId,
            Abbreviation: this.abbrev.Abbreviation,
          };

      this.$http.ejecutar(metodo, ruta, LineAbbrevJson, { root: 'LineAbbrevJson' })
      .then(response => {
        this.$notify({
          group: 'container',
          title: '¡'+this.$t('label.success')+'!',
          text: response.data.data[0].Response,
          type: "success"
        });
        this.getLineaAbreviaturas(this.lineaData.ShippingLineId);
        this.resetForm();
      }).catch(err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      }).then(() => {
        this.isSubmit = false;
      });
    } catch (e) {
    this.$notify({  
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error" });
  }
}
function resetForm() {
  this.abbrev.LineAbbrevId = '';
  //this.abbrev.ShippingLineId = '';
  this.abbrev.Abbreviation = '';
  this.abbrev.Status = 1;
  this.oldStatus = 1;
  this.editMode = false;

  this.$v.$reset();
}
function setForm(oldAbbrev) {
  this.abbrev.LineAbbrevId = oldAbbrev.LineAbbrevId;
  this.abbrev.ShippingLineId = oldAbbrev.ShippingLineId;
  this.abbrev.Abbreviation = oldAbbrev.Abbreviation;
  this.abbrev.Status = oldAbbrev.FgActLineAbbrev?1:0;
  this.oldStatus = oldAbbrev.FgActLineAbbrev?1:0;
  this.$v.$touch();
}
function statusConfirmation(){
  if(this.abbrev.Status === this.oldStatus)
    this.submit();
  else{
    this.$swal.fire({
    text: `${this.$t('label.changeStatusQuestion')} ${this.abbrev.Abbreviation}?`,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: '#42AA91',
    cancelButtonColor: '#E1373F',
    confirmButtonText: this.$t('button.confirm'),
    cancelButtonText: this.$t('button.cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.submit();
      }
    });
  }
}

//Computeds
function formatedItems() {
  return this.items.map((item) => Object.assign({}, item, {
    RowNumber: item.Nro,
    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : '',
    _classes: item.Status === 'ACTIVO'? '': 'table-danger'
  }));
}
function title() {
  return this.lineaData ?`${this.$t('label.shippingLineAbbreviations')}: ${this.lineaData.ShippingLineName}`
    :this.$t('label.shippingLineAbbreviations')+': ';
}
function statusOptions() {
  return [
    {
      value: 1,
      label: this.$t('label.ACTIVO')
    },
    {
      value: 0,
      label: this.$t('label.INACTIVO')
    }
  ]
}
function statusSelectColor(){
  return this.abbrev.Status === 1;
}
function isAddValid(){
  return this.$v.$invalid || this.isSubmit || this.editMode;
}
function isEditValid() {
  return this.$v.$invalid || this.isSubmit;
}

export default {
  name: 'abreviaturas',
  mixins: [GeneralMixin],
  props: {
    modal: Boolean,
    lineaData: {
      type: Object,
      default: () => { return {} }
    }
  },
  data,
  validations: AbbrevValidations,
  directives: UpperCase,
  methods: {
    getLineaAbreviaturas,
    toggle,
    toggleEdit,
    submit,
    statusConfirmation,
    setForm,
    resetForm,
    statusSelectColor
  },
  computed: {
    formatedItems,
    title,
    statusOptions,
    isAddValid,
    isEditValid,
    fields
  },
  watch: {
    modal: function(val) {
      this.toggle(val);
    },
    modalActive: function(val) {
      this.$emit('update:modal', val);
    }
  }
}
</script>

<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>